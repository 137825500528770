import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import DashboardLayout from './layouts';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import RoutesByRole from './utils/rbac/RoutesByRole';
import { PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPEL5 } from './utils/rbac/papeis';

interface RoutesList {
  exact?: boolean;
  path?: string;
  component?: any;
  routes?: Array<RoutesList>;
  guard?: any;
  layout?: any;
  allowedRoles?: string[];
}
export const renderRoutes = (routes: Array<RoutesList> = []): unknown => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <RoutesByRole
            key={`${route.path} ${i}`}
            path={route.path}
            exact={route.exact}
            allowedRoles={route.allowedRoles}
            render={(props): JSX.Element => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/ErroPagina'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/ativarConta',
    component: lazy(() => import('./views/auth/AtivaContaView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/nao-autorizado',
    component: lazy(() => import('./views/errors/NaoAutorizado'))
  },
  {
    // rota empresa inativa
    exact: true,
    guard: AuthGuard,
    path: '/empresa-inativa',
    component: lazy(() => import('./views/errors/EmpresaInativa'))
  },

  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: (): JSX.Element => <Redirect to="/conta" />
      },
      {
        // tela inicial quando entra no sistema
        exact: true,
        path: '/conta',
        allowedRoles: [PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/account/AccountView'))
      },
      {
        exact: true,
        path: '/gerenciar-usuarios',
        component: (): JSX.Element => <Redirect to="/gerenciar-usuarios/lista" />
      },
      {
        // ver lista de usuarios, editar usuarios (nome, loja e cargo), atribuir dashboards aos usuarios, atribuir empresas aos usuarios, excluir usuarios
        exact: true,
        path: '/gerenciar-usuarios/lista',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/users/UsersListView'))
      },
      {
        // ver, criar e editar colaboradores
        exact: true,
        path: '/gerenciar-colaborador/lista',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/metas/ColaboradorView'))
      },
      {
        exact: true,
        path: '/gerenciar-empresas',
        component: (): JSX.Element => <Redirect to="/gerenciar-empresas/lista" />
      },
      {
        // pode-se ver e excluir as empresas listadas, clicando em 'VER' pode-se editar a empresa
        exact: true,
        path: '/gerenciar-empresas/lista',
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/emp/EmpListView'))
      },

      {
        // rota motivo status empresa inativa
        exact: true,
        allowedRoles: [PAPEL4],
        path: '/motivosStatusEmpresa',
        component: lazy(() => import('./views/motivosStatusEmpresa/Index'))
      }

      ,
      {
        // classificações de loja
        exact: true,
        path: '/classificacoes-loja',
        allowedRoles: [PAPEL4, PAPEL5],
        component: lazy(() => import('./views/classificacoes/Index'))
      },
      {
        // gerenciar paineis, criar, editar e excluir paineis
        exact: true,
        path: '/paineis',
        allowedRoles: [PAPEL4],
        component: lazy(() => import('./views/paineis/Gerenciar'))
      },
      {
        // ver as categorias criadas, criar, editar e excluir categorias de dashboards
        exact: true,
        path: '/paineis/categorias',
        allowedRoles: [PAPEL4],
        component: lazy(() => import('./views/paineis/Categorias'))
      },
      {
        // ver os grupos de controle criados, criar, editar  e excluir grupos de controle
        exact: true,
        path: '/grupos-controle',
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/emp/GruposDeControleListView/Index'))
      },
      {
        // criar meta
        exact: true,
        path: '/metas/nova',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/metas/CriarMetasView'))
      },
      {
        // ver as metas criadas, mudar o status da meta, deletar metas, e mudar para tela de edicao de uma dada meta
        exact: true,
        path: '/metas/gerenciar',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/metas/GerenciarMetasView'))
      },
      {
        // editar uma meta, que foi selecionada em /metas/gerenciar
        exact: true,
        path: '/metas/:id/:empId/:lojaId/:ano/:mes/editar',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/metas/EditarMetaView'))
      },
      {
        // criar um fator de correcao
        exact: true,
        path: '/fator/nova',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/fator/CriarFatorView'))
      },
      {
        // ver fatores criados, mudar o status dos fatores, mudar para tela de edicao ao clicar no id em um fator
        exact: true,
        path: '/fator/gerenciar',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/fator/GerenciarFatorView'))
      },
      {
        // editar um fator de correcao
        exact: true,
        path: '/fator/:empId/:lojaId/:ano/:mes/editar',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/fator/EditarFatorView'))
      },
      {
        // criar campanha
        exact: true,
        path: '/campanha/nova',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/campanha/CriarCampanhaView'))
      },
      {
        // pode ver as campanhas criadas, editar, deletar, duplicar campanhas (mudar para tela de editar metadadados de uma dada campanha)
        exact: true,
        path: '/campanha/gerenciar',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/campanha/GerenciarCampanhaView'))
      },
      {
        // editar uma campanha
        exact: true,
        path: '/campanha/:empId/:campanhaId/editar',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/campanha/EditarCampanhaView'))
      },
      {
        // pode ver e editar a Curva ABC de uma empresa
        exact: true,
        path: '/configuracoes/curvaAbc',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/configuracoes/CriarCurvaAbcView'))
      },
      {
        // pode ver e editar o Excesso de Estoque de uma empresa
        exact: true,
        path: '/configuracoes/estoque',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/configuracoes/GerenciarEstoqueView'))
      },
      {
        exact: true,
        path: '/empresas/:empresaId/lojas/:lojaId/paineis/:painelId',
        allowedRoles: [PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/paineis/Visualizar/PainelView'))
      },
      {
        exact: true,
        path: '/empresas/:empresaId/paineis/:painelId',
        allowedRoles: [PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/paineis/Visualizar/PainelView'))
      },
      {
        exact: true,
        path: '/empresas/:empresaId',
        allowedRoles: [PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        component: lazy(() => import('./views/account/AccountView'))
      },
      {
        // pode ver tela parametrosIntegracao power Bi
        exact: true,
        path: '/parametrosBi/parametrosIntegracao',
        allowedRoles: [PAPEL4],
        component: lazy(() => import('./views/parametrosBi/parametrosIntegracao/Index'))
      },
      {
        // pode ver tela parametrosEmbedded power Bi
        exact: true,
        path: '/parametrosBi/parametrosEmbedded',
        allowedRoles: [PAPEL4],
        component: lazy(() => import('./views/parametrosBi/parametrosEmbedded/Index'))
      },
      {
        exact: true,
        path: '/solicitacoes/novaAtivacao',
        allowedRoles: [PAPEL3,PAPEL4,PAPEL5],
        component: lazy(() => import('./views/solicitacoes/novaAtivacao'))
      },
      {
        exact: true,
        path: '/solicitacoes/suporte',
        allowedRoles: [PAPEL3,PAPEL4,PAPEL5],
        component: lazy(() => import('./views/solicitacoes/suporte'))
      },
      {
        exact: true,
        path: '/app',
        component: (): JSX.Element => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
